var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loader
    ? _c("SkeletonTable", {
        attrs: {
          rows: _vm.loaderOptions.rows,
          columns: _vm.loaderOptions.columns,
        },
      })
    : _c(
        "DataTable",
        {
          attrs: {
            fields: _vm.fields,
            items: _vm.items,
            "sort-key": _vm.primaryKey,
            "sort-compare": _vm.sortCompare,
            "page-size": 25,
            "scrollable-table": _vm.tableIsScrollable,
            "last-col-sticky": _vm.lastColSticky,
            "tbody-tr-class": _vm.tbodyTrClass,
          },
          on: {
            "row-clicked": function ($event) {
              return _vm.$emit("row-clicked", $event)
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "filter-slot",
                fn: function () {
                  return [_vm._t("filter-slot")]
                },
                proxy: true,
              },
              _vm._l(_vm.$scopedSlots, function (_, name) {
                return {
                  key: name,
                  fn: function (slotData) {
                    return [_vm._t(name, null, null, slotData)]
                  },
                }
              }),
            ],
            null,
            true
          ),
        },
        [
          _vm._v(" "),
          _vm._l(_vm.$slots, function (_, name) {
            return _vm._t(name)
          }),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }